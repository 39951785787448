<template>
  <v-card class="pa-4 admin-report-card">
    <v-row class="report-wrap">
      <v-col
        md="3"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <p class="text-h6">
          POS Payment Summary
          <v-icon
            class="ml-2"
            color="success"
            :loading="fetching"
            @click="exportCsv">
            mdi-microsoft-excel
          </v-icon>
        </p>
      </v-col>
      <v-col
        md="4"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <WarehouseSelect
          v-model="warehouseSelected"
          :items="warehouses"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          outlined
          :disabled="fetching"
          class="mr-2" />
      </v-col>
      <v-col
        md="4"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <date-picker
          v-model="dateSelected"
          :disabled="fetching" />
      </v-col>
      <v-col cols="1">
        <v-btn
          class="mt-2"
          :loading="fetching"
          @click="fetchReport()">
          Go
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="options.itemsPerPage"
          :server-items-length="-1"
          :options.sync="options"
          class="elevation-1"
          :loading="fetching"
          dense
          :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
          @update:options="fetchReport">
          <template v-slot:[`item.inputAmount`]="{ item }">
            {{ item.inputAmount | showNumberFormat() }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ item.amount | showNumberFormat() }}
          </template>
          <template v-slot:[`item.diff`]="{ item }">
            {{ item.diff | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NewReportProvider from '@/resources/NewReportProvider'
import { ExportToCsv } from 'export-to-csv'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import DatePicker from '../components/DatesPicker.vue'

const NewReportService = new NewReportProvider()

export default {
  components: {
    DatePicker,
    WarehouseSelect
  },
  filters: {
    showDate (date) {
      return dayjs(date).utc().format('DD-MM-YYYY')
    }
  },
  data () {
    return {
      fetching: false,
      warehouseSelected: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortDesc: [true],
        sortBy: ['createdAt']
      },
      summary: {},
      headers: [
        { text: 'Date', value: 'date', width: 170 },
        { text: 'Warehouse', value: 'warehouse', width: 150 },
        { text: 'Payment', value: 'payment', width: 150, align: 'center' },
        { text: 'ยอดรับชำระ', value: 'inputAmount', width: 150, align: 'right' },
        { text: 'ยอดในระบบ', value: 'amount', width: 150, align: 'right' },
        { text: 'Diff', value: 'diff', width: 150, align: 'right' }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()],
      items: []
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return [
        {
          id: 0,
          name: 'All',
          code: 'all'
        },
        ...this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
      ]
    }
  },
  watch: {
    dateSelected: {
      handler () {
        this.fetchReport()
      },
      deep: true
    },
    warehouseSelected () {
      this.fetchReport()
    }
  },
  methods: {
    async fetchReport () {
      if (!this.warehouseSelected && this.warehouseSelected !== 0) {
        return
      }

      this.fetching = true
      this.items = []
      this.total = 0

      await this.fetchMainReport()

      this.fetching = false
    },
    async fetchMainReport () {
      try {
        const { data } = await NewReportService.getPosDailyReport({
          warehouse: this.warehouseSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })

        this.items = data.map((d) => ({
          date: this.$options.filters.showDate(d.date),
          warehouse: d.warehouse.name,
          payment: d.payment.name,
          inputAmount: d.payment.inputAmount,
          amount: d.payment.amount,
          diff: d.payment.amount - d.payment.inputAmount
        }))
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${error.message}`,
          type: 'error'
        })
      }
    },
    exportCsv () {
      const startDate = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const endDate = this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const options = {
          filename: `POS_PAYMENT_SUMMARY_${startDate}_${endDate}_${this.warehouseSelected}`,
          showLabels: true,
          useKeysAsHeaders: true
        }
      const csvExporter = new ExportToCsv(options)

      const data = this.items.map((item) => {
        const result = {}
        this.headers.forEach((h) => {
          result[h.text] = item[h.value]
        })

        return result
      })
      csvExporter.generateCsv(data)
    }
  }
}
</script>
